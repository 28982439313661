import React from 'react';
import { Link } from 'gatsby';

import ArrowR from '@svg/arrow-r.svg';

const PostCard = ({ title, slug }) => {
  return (
    <Link className="bg-solar flex flex-col gap-y-6 p-4 lg:p-6" to={`/news/${slug?.current}`}>
      <h2 className="isH2 font-serif pr-16">{title}</h2>
      <span className="flex gap-x-2 items-center font-h-medium">
        Read More <ArrowR className="w-3 fill-current" />
      </span>
    </Link>
  );
};

export default PostCard;
