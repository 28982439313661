import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '@components/layout';
import PortableTextBlock from '@components/sanity/portableTextBlock';
import PostCard from '@components/blog/postCard';
import { PageMeta } from '@components/pageMeta';

const NewsPortal = ({ data: { page, posts } }) => {
  return (
    <Layout>
      <PageMeta {...page?.seo} />
      <div className="px-gutter grid grid-cols-12">
        <h1 className="isH1 col-start-1 col-end-12 lg:col-end-6">{page.title}</h1>
        <PortableTextBlock text={page.text} className="col-start-1 col-end-12 lg:col-start-7" />
      </div>
      <div className="px-gutter grid grid-cols-1 gap-y-4 pb-16 md:grid-cols-3 md:gap-4 md:items-start lg:mt-16 lg:gap-6">
        {posts?.nodes.map((post) => (
          <PostCard {...post} />
        ))}
      </div>
    </Layout>
  );
};

export default NewsPortal;

export const pageQuery = graphql`
  query newsPortalQuery {
    page: sanityBlogPortal {
      seo {
        ...SEO
      }
      title
      text: _rawText(resolveReferences: { maxDepth: 10 })
    }
    posts: allSanityBlogPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        key: _key
        title
        slug {
          current
        }
      }
    }
  }
`;
